import { Login } from "@components/index";

export async function getStaticProps() {
  return {
    props: {
      showPrimaryNav: true,
      showSecondaryNav: false,
      showMobileNav: false,
      showFooter: true,
      showMobileFooter: false,
    }, // Used in Layout to determine if nav should be shown
  };
}

const LoginPage = () => {
  return (
    <div className="onboardingContainer w-full h-full">
      <Login />
    </div>
  );
};

export default LoginPage;
